










import { MetaInfo } from 'vue-meta'
import { Component, Vue } from 'vue-property-decorator'

import RolesTable from '@/components/tables/RolesTable.vue'

@Component({
  components: { RolesTable },
})
export default class RolesList extends Vue {
  private disabledEdit = process.env.VUE_APP_ROLES_FORM_ENABLE === '0'

  private metaInfo (): MetaInfo {
    return {
      title: 'Группы прав доступа',
    }
  }
}
