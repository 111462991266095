

















import { isEqual } from 'lodash'
import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins } from 'vue-property-decorator'

import NotifyMixin from '@/mixins/NotifyMixin'
import ManagerRolesModule from '@/store/modules/manager/roles'
import { ITableOptions, RoleShortResource } from '@/store/types'
import { tableFooterOptions } from '@/utils/constants'

@Component({
  inheritAttrs: false,
})
export default class RolesTable extends Mixins(NotifyMixin) {
  private footerOptions = tableFooterOptions

  private get filter () {
    return ManagerRolesModule.rolesFilter
  }

  private set filter (filter: ITableOptions) {
    if (!isEqual(filter, this.filter) || this.roles.length === 0) {
      ManagerRolesModule.setRolesFilter(filter)
      this.fetchRoles()
    }
  }

  private get headers () {
    return [
      { text: 'Название', value: 'title' },
      { cellClass: 'cell-width-50', text: 'Права', value: 'groups' },
      { text: 'Пользователи', value: 'usersCount' },
    ]
  }

  private get roles () {
    return ManagerRolesModule.roles
  }

  @Bind
  @Debounce(300)
  private fetchRoles () {
    ManagerRolesModule.fetchRoles()
      .catch(this.notifyError)
  }

  private handleRowClick (role: RoleShortResource) {
    this.$router.push({
      name: 'manager.users.roles.item',
      params: { roleUUID: role.uuid },
    })
  }
}
